
import { defineComponent } from 'vue'
import Select from '@/components/atoms/CustomSelect.vue'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import LocalChanger from '@/components/molecules/LocalChanger.vue'
import BackgroundLogo from '@/components/molecules/BackgroundLogo.vue'
import { Form, Field, ErrorMessage } from 'vee-validate'

export default defineComponent({
  name: 'FirstLogin',
  data () {
    return {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      selectTitle: 'UA +380',
      countryCode: '+380',
      firstTimeLoginError: null,
      countryCodeData: {},
      data: [{}]
    }
  },
  watch: {
    phoneNumber: {
      deep: true,
      handler () {
        if (this.firstTimeLoginError) {
          this.firstTimeLoginError.phone = null
        }
      },
      immediate: true
    },
    lastName: {
      deep: true,
      handler () {
        if (this.firstTimeLoginError) {
          this.firstTimeLoginError.last_name = null
        }
      },
      immediate: true
    },
    firstName: {
      deep: true,
      handler () {
        if (this.firstTimeLoginError) {
          this.firstTimeLoginError.first_name = null
        }
      },
      immediate: true
    }
  },
  mounted () {
    const lang = JSON.parse(localStorage.getItem('lang'))
    this.activeLang = lang
    this.getCountryCodes()
  },
  components: {
    Select,
    AlertBlock,
    LocalChanger,
    BackgroundLogo,
    Form,
    ErrorMessage,
    Field
  },
  methods: {
    handleOptionSelected (selectedOption) {
      this.countryCode = selectedOption.value
    },
    setLocal (lang) {
      localStorage.setItem('lang', JSON.stringify(lang))
      this.activeLang = lang
    },
    getCountryCodes () {
      this.$store.dispatch('auth/getCountryCodes').then(
        (res) => {
          this.countryCodeData = res.data.map(({ country, code }) => ({
            title: `${country} ${code}`,
            value: code
          }))
          this.data = this.countryCodeData
        },
        (error) => {
          console.log(error)
        }
      )
    },
    firstTimeLogin (data) {
      data.phone = this.countryCode + data.phone
      this.$store.dispatch('auth/firstTimeLogin', data).then(
        () => {
          this.$router.push('/dashboard/main')
        },
        (error) => {
          this.firstTimeLoginError = error.response.data.errors
        }
      )
    },
    hideError () {
      this.firstTimeLoginError.phone = null
    }
  }
})
