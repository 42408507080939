import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3235c183"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "first-login" }
const _hoisted_2 = { class: "first-login__first-login" }
const _hoisted_3 = { class: "first-login__title" }
const _hoisted_4 = { class: "first-login__text" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  for: "phone",
  class: "form-group-label active"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "first-login__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackgroundLogo = _resolveComponent("BackgroundLogo")!
  const _component_LocalChanger = _resolveComponent("LocalChanger")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BackgroundLogo),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LocalChanger),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("message.first_login.title")), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("message.first_login.text")), 1),
      _createVNode(_component_Form, { onSubmit: _ctx.firstTimeLogin }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Field, {
              name: "first_name",
              type: "text",
              placeholder: _ctx.$t('message.settings.first_name'),
              class: "form-control",
              modelValue: this.firstName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.firstName) = $event))
            }, null, 8, ["placeholder", "modelValue"]),
            _createElementVNode("label", {
              for: "first_name",
              class: _normalizeClass(['form-group-label', (this.firstName + '').length > 0 && 'active'])
            }, _toDisplayString(_ctx.$t('message.settings.first_name')), 3),
            _createVNode(_component_ErrorMessage, {
              name: "first_name",
              class: "error-feedback"
            }),
            (this.firstTimeLoginError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_AlertBlock, {
                    message: this.firstTimeLoginError.first_name
                  }, null, 8, ["message"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Field, {
              name: "last_name",
              type: "text",
              placeholder: _ctx.$t('message.settings.last_name'),
              class: "form-control",
              modelValue: this.lastName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.lastName) = $event))
            }, null, 8, ["placeholder", "modelValue"]),
            _createElementVNode("label", {
              for: "last_name",
              class: _normalizeClass(['form-group-label', (this.lastName + '').length > 0 && 'active'])
            }, _toDisplayString(_ctx.$t('message.settings.last_name')), 3),
            _createVNode(_component_ErrorMessage, {
              name: "last_name",
              class: "error-feedback"
            }),
            (this.firstTimeLoginError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_AlertBlock, {
                    message: this.firstTimeLoginError.last_name
                  }, null, 8, ["message"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Select, {
              data: this.data,
              title: 'title',
              "select-name": this.selectTitle,
              onOptionSelected: _ctx.handleOptionSelected,
              onClick: this.hideError
            }, null, 8, ["data", "select-name", "onOptionSelected", "onClick"]),
            _createVNode(_component_Field, {
              name: "phone",
              type: "number",
              class: "form-control first-login__phone",
              modelValue: this.phoneNumber,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.phoneNumber) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('message.settings.phone_number')), 1),
            _createVNode(_component_ErrorMessage, {
              name: "phone",
              class: "error-feedback"
            }),
            (this.firstTimeLoginError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_AlertBlock, {
                    message: this.firstTimeLoginError.phone
                  }, null, 8, ["message"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("button", _hoisted_13, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.login_form.login')), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ])
  ]))
}